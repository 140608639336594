import React, { useState, useEffect } from 'react';
import NavbarTop from '../navbar/navbarTop';
import NavbarBottom from '../navbar/navbarBottom';
import './css/game.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faSquarePollVertical } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

const GamePage = () => {
    const [game, setGame] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const currentDate = new Date();
            const day = currentDate.getDate().toString().padStart(2, '0');
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const year = currentDate.getFullYear();
            const dateString = `${day}-${month}-${year}`;

            const querySnapshot = await getDocs(collection(db, dateString));
            const allData = [];
            querySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
                allData.push({ id: doc.id, ...doc.data() });
            });

            // Filter out any items in the allData array that have undefined gameName
            const validData = allData.filter(item => item.gameName);

            // Sort the validData array by gameName in ascending order
            validData.sort((a, b) => a.gameName.localeCompare(b.gameName));

            console.log('Sorted data:', validData);

            // Set the state with the sorted array
            setGame(validData);
        };
        fetchData();
    }, []);

    function getButtonState(gameTimeStart, gameTimeEnd, id) {
        const currentDate = new Date();
        const currentHours = currentDate.getHours(); // Get current hour in 24-hour format
        const currentMinutes = currentDate.getMinutes().toString().padStart(2, '0'); // Get current minutes with leading zero

        // Split start and end times into hours and minutes
        const [startHour, startMinute] = gameTimeStart.split(':').map(Number);
        const [endHour, endMinute] = gameTimeEnd.split(':').map(Number);

        // Check if current time is within the game time window
        if (currentHours > startHour && currentHours < endHour) {
            // Game is currently active
            return (
                <Link to={`/game/gamearchive/${id}`}>
                    <button style={{ backgroundColor: 'var(--color-primary)' }}>Play Game</button>
                </Link>
            );
        } else if (currentHours === startHour) {
            // Check if game is starting within the same hour
            if (currentMinutes < startMinute) {
                return (
                    <Link to={`/game/gamearchive/${id}`}>
                        <button style={{ backgroundColor: 'var(--color-primary)' }}>Play Game</button>
                    </Link>
                );
            } else {
                return (
                    <Link to={`/game/gamearchive/${id}`}>
                        <button style={{ backgroundColor: 'var(--color-primary)' }}>Play Game</button>
                    </Link>
                );
            }
        } else if (currentHours === endHour) {
            // Check if game is ending within the same hour
            if (currentMinutes <= endMinute) {
                return (
                    <Link to={`/game/gamearchive/${id}`}>
                        <button style={{ backgroundColor: 'var(--color-primary)' }}>Play Game</button>
                    </Link>
                );
            } else {
                return <button disabled style={{ backgroundColor: 'var(--color-secondary)' }}>Completed</button>;
            }
        } else if (currentHours < startHour) {
            // Game has not started yet
            return (
                <Link to={`/game/gamearchive/${id}`}>
                    <button style={{ backgroundColor: 'var(--color-primary)' }}>Play Game</button>
                </Link>
            );
        } else {
            // Game has already ended
            return <button disabled style={{ backgroundColor: 'var(--color-secondary)' }}>Completed</button>;
        }
    }

    return (
        <div>
            <NavbarTop />
            <div className="gamepage">
                {game.length === 0 ? (
                    <p className="no-games">NO GAMES NOW</p>
                ) : (
                    game.map((item) => (
                        <div className='gamePage-Component' key={item.id}>
                            <div className="gamePage-Component-child activated">
                                <p className="gameTitle">
                                    Bazi   {item.gameName}
                                </p>
                                <div className="baziTime-component componentGame--iconText">
                                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                                    <p><b>Time :</b> {item.gameTimeStart} - {item.gameTimeEnd}</p>
                                </div>
                                <div className="baziResult-component componentGame--iconText">
                                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                                    <p><b> Result Time:</b> {item.resultTime}</p>
                                </div>
                                <div className="baziResult-component componentGame--iconText">
                                    <FontAwesomeIcon icon={faSquarePollVertical}></FontAwesomeIcon>
                                    <p><b>Result Number:</b> {item.result}</p>
                                </div>
                            </div>
                            {getButtonState(item.gameTimeStart, item.gameTimeEnd, item.id)}
                        </div>
                    ))
                )}
            </div>
            <NavbarBottom />
        </div>
    );
};

export default GamePage;