import React, { useEffect, useState } from 'react';
import './../components/css/table.css';
import './css/bethistory.css';
import NavbarTop from '../navbar/navbarTop';
import NavbarBottom from '../navbar/navbarBottom';
import { auth, db } from '../config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

const BetHistory = () => {
    const [betHistory, setBetHistory] = useState([]);
    const [filteredBetHistory, setFilteredBetHistory] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [totalAmountPlayed, setTotalAmountPlayed] = useState(0);
    const [totalWinAmount, setTotalWinAmount] = useState(0);
    const [totalProfitLoss, setTotalProfitLoss] = useState(0);
    const [tableTotalAmountPlayed, setTableTotalAmountPlayed] = useState(0);
    const [tableTotalWinAmount, setTableTotalWinAmount] = useState(0);
    const [tableTotalProfitLoss, setTableTotalProfitLoss] = useState(0);

    const getCurrentDateInIST = () => {
        const offset = 5.5 * 60 * 60 * 1000; // IST offset from UTC in milliseconds
        const now = new Date();
        const istTime = new Date(now.getTime() + offset);
        const year = istTime.getUTCFullYear();
        const month = (istTime.getUTCMonth() + 1).toString().padStart(2, '0');
        const date = istTime.getUTCDate().toString().padStart(2, '0');
        return `${year}-${month}-${date}`;
    };

    useEffect(() => {
        const today = getCurrentDateInIST();
        setStartDate(today);
        setEndDate(today);

        const fetchBetHistory = async () => {
            try {
                const user = auth.currentUser;
                if (!user) {
                    throw new Error('User not logged in');
                }
                const q = query(collection(db, 'userProfile'), where('uid', '==', user.uid));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    throw new Error('User profile not found');
                }

                const userProfileData = querySnapshot.docs[0].data();
                const history = userProfileData.betLog || [];
                // Reverse the bet history array
                const reversedHistory = history.reverse();
                setBetHistory(reversedHistory);
                // Filter bets for today
                const filtered = reversedHistory.filter(bet => {
                    const betDate = new Date(bet.date.split('/').reverse().join('-'));
                    return betDate.toDateString() === new Date(today).toDateString();
                });

                setFilteredBetHistory(filtered);
                calculateTotals(filtered);
            } catch (error) {
                console.error('Error fetching bet history:', error);
            }
        };

        if (auth.currentUser) {
            fetchBetHistory();
        }
    }, []);

    useEffect(() => {
        calculateTableTotals(filteredBetHistory);
    }, [filteredBetHistory]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleSearch = () => {
        if (!startDate || !endDate) {
            alert('Please enter both start and end dates.');
            return;
        }

        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999);

        const filtered = betHistory.filter(bet => {
            const betDate = new Date(bet.date.split('/').reverse().join('-')); // Convert bet date to YYYY-MM-DD
            return betDate >= start && betDate <= end;
        });

        setFilteredBetHistory(filtered.length > 0 ? filtered : []);
        calculateTotals(filtered);
    };

    const handleClear = () => {
        const today = getCurrentDateInIST();
        setStartDate(today);
        setEndDate(today);
        // Filter bets for today
        const filtered = betHistory.filter(bet => {
            const betDate = new Date(bet.date.split('/').reverse().join('-'));
            return betDate.toDateString() === new Date(today).toDateString();
        });
        setFilteredBetHistory(filtered);
        calculateTotals(filtered);
    };

    const calculateTotals = (filteredBets) => {
        const totals = filteredBets.reduce((acc, bet) => {
            const amountWon = bet.status === 'win' ? (
                bet.number.toString().length === 1 ?
                    bet.amount * 9 :
                    (bet.number.toString().length === 3 ? bet.amount * 100 : 0)
            ) : 0;

            acc.amountPlayed += bet.amount;
            acc.winAmount += amountWon;
            acc.profitLoss += (amountWon - bet.amount);

            return acc;
        }, { amountPlayed: 0, winAmount: 0, profitLoss: 0 });

        setTotalAmountPlayed(totals.amountPlayed);
        setTotalWinAmount(totals.winAmount);
        setTotalProfitLoss(totals.profitLoss);
    };

    const calculateTableTotals = (filteredBets) => {
        const totals = filteredBets.reduce((acc, bet) => {
            const amountWon = bet.status === 'win' ? (
                bet.number.toString().length === 1 ?
                    bet.amount * 9 :
                    (bet.number.toString().length === 3 ? bet.amount * 100 : 0)
            ) : 0;

            acc.amountPlayed += bet.amount;
            acc.winAmount += amountWon;
            acc.profitLoss += (amountWon - bet.amount);

            return acc;
        }, { amountPlayed: 0, winAmount: 0, profitLoss: 0 });

        setTableTotalAmountPlayed(totals.amountPlayed);
        setTableTotalWinAmount(totals.winAmount);
        setTableTotalProfitLoss(totals.profitLoss);
    };

    return (
        <div>
            <NavbarTop />
            <div className="bet-history-container">
                <div className="bet-history-head-container">
                    <h2>Search Bet History</h2>
                    <div className="search-inputs">
                        <label htmlFor="">
                            <p>Start Date</p>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                placeholder='Enter Date'
                            />
                        </label>
                        <label htmlFor="">
                            <p>End Date</p>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                placeholder='Enter Date'
                            />
                        </label>
                    </div>
                    <div className="search-buttons">
                        <button id='search-bet-results' onClick={handleSearch}>Search Results</button>
                        <button id='clear-search-results' onClick={handleClear}>Clear</button>
                    </div>
                </div>
                <div className="amountInvstDetails">
                    <div className="totalAmntPlayed">
                        <p><b>Total Amount Played:</b> {totalAmountPlayed}</p>
                    </div>
                    <div className="totalWon">
                        <p><b>Total Amount Won:</b> {totalWinAmount}</p>
                    </div>
                    <div className="totalProfitLoss">
                        <p><b>Total Profit/Loss:</b> {totalProfitLoss}</p>
                    </div>
                </div>
                <div className="bet-history-table">
                    <div className="table-wrapper" style={{ padding: '0px' }}>
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>Game</th>
                                    <th>Date</th>
                                    <th>Number</th>
                                    <th>Play</th>
                                    <th>Win</th>
                                    <th>P/L</th>
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    {filteredBetHistory.length > 0 ? (
                                        filteredBetHistory.map((bet, index) => {
                                            const amountWon = bet.status === 'win' ? (
                                                bet.number.toString().length === 1 ?
                                                    bet.amount * 9 :
                                                    (bet.number.toString().length === 3 ? bet.amount * 100 : 0)
                                            ) : 0;

                                            const profit = amountWon - bet.amount;

                                            return (

                                                <tr key={index} className={bet.status === 'win' ? 'inTable-Yellow-Color' : ''}>
                                                    <td>Bazi {bet.gameName}</td>
                                                    <td>{bet.date}</td>
                                                    <td>{bet.number}</td>
                                                    <td>{bet.amount}</td>
                                                    <td>
                                                        {amountWon > 0 ? amountWon : '-'}
                                                    </td>
                                                    <td>{profit}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="6">NO BETS</td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none', fontSize: '1.2rem', fontWeight: '600', color: '#000000' }}> Total </td>
                                        <td style={{ fontSize: '1.2rem', fontWeight: '600', color: '#fff' }}>{tableTotalAmountPlayed}</td>
                                        <td style={{ fontSize: '1.2rem', fontWeight: '600', color: '#fff' }}>{tableTotalWinAmount}</td>
                                        <td style={{ fontSize: '1.2rem', fontWeight: '600', color: '#fff' }}>{tableTotalProfitLoss}</td>
                                    </tr>
                                </>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <NavbarBottom />
        </div>
    );
}

export default BetHistory;
