import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import './css/table.css';

const ResultTable = () => {
    const [todayDate, setTodayDate] = useState(null);
    const [gamesToday, setGamesToday] = useState([]);
    const [gamesLast30Days, setGamesLast30Days] = useState([]);
    const [gamesAll, setGamesAll] = useState([]);
    const [showToday, setShowToday] = useState(true);
    const [showLast30Days, setShowLast30Days] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [loadingToday, setLoadingToday] = useState(true);
    const [loadingLast30Days, setLoadingLast30Days] = useState(false);
    const [loadingAll, setLoadingAll] = useState(false);

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        const currentDate = new Date();
        setTodayDate(formatDate(currentDate));
    }, []);

    useEffect(() => {
        const fetchGameResults = async () => {
            try {
                const fetchCollectionResults = async (date) => {
                    const querySnapshot = await getDocs(collection(db, date));
                    return querySnapshot.docs.map(doc => ({
                        ...doc.data(),
                        date: date,
                        pattiResult: doc.data().result ?? '--',
                        tripleResult: doc.data().singleResult ?? '--'
                    })).sort((a, b) => parseInt(a.gameName) - parseInt(b.gameName));
                };

                // Fetch today's results
                if (todayDate) {
                    const fetchedGamesToday = await fetchCollectionResults(todayDate);
                    setGamesToday(fetchedGamesToday);
                    setLoadingToday(false);

                    // Fetch results for the last 30 days
                    setLoadingLast30Days(true);
                    const last30DaysResults = {};
                    const currentDate = new Date();
                    for (let i = 0; i < 30; i++) {
                        const date = new Date(currentDate);
                        date.setDate(currentDate.getDate() - i);
                        const formattedDate = formatDate(date);
                        try {
                            const fetchedGames = await fetchCollectionResults(formattedDate);
                            last30DaysResults[formattedDate] = fetchedGames;
                        } catch (error) {
                            console.log(`No data for ${formattedDate}`);
                        }
                    }
                    setGamesLast30Days(last30DaysResults);
                    setLoadingLast30Days(false);

                    // Fetch all results
                    setLoadingAll(true);
                    const allResults = {};
                    const fetchAllResults = async () => {
                        for (let i = 0; i < 365; i++) {
                            const date = new Date(currentDate);
                            date.setDate(currentDate.getDate() - i);
                            const formattedDate = formatDate(date);
                            try {
                                const fetchedGames = await fetchCollectionResults(formattedDate);
                                allResults[formattedDate] = fetchedGames;
                            } catch (error) {
                                console.log(`No data for ${formattedDate}`);
                            }
                        }
                    };
                    await fetchAllResults();
                    setGamesAll(allResults);
                    setLoadingAll(false);
                }
            } catch (error) {
                console.error('Error fetching game results:', error);
            }
        };

        fetchGameResults();
    }, [todayDate]);

    const renderTableRows = (games) => {
        return Object.keys(games).map((date) => (
            <tr key={date}>
                <td>{date}</td>
                {games[date].map((game, index) => (
                    <td key={`${date}-${index}`}>
                        {game.pattiResult}<br />{game.tripleResult}
                    </td>
                ))}
            </tr>
        ));
    };

    return (
        <div className="accordion">
            <div className="accordion-item">
                <div className="accordion-title" onClick={() => setShowToday(!showToday)}>
                    <h3>Today's Results</h3>
                </div>
                {showToday && (
                    <div className="accordion-content">
                        {loadingToday ? (
                            <div>Loading today's table...</div>
                        ) : (
                            <div className="table-wrapper">
                                <h3>Showing Result For {todayDate}</h3>
                                <table className="fl-table">
                                    <thead>
                                        <tr>
                                            <th>Bazi 1</th>
                                            <th>Bazi 2</th>
                                            <th>Bazi 3</th>
                                            <th>Bazi 4</th>
                                            <th>Bazi 5</th>
                                            <th>Bazi 6</th>
                                            <th>Bazi 7</th>
                                            <th>Bazi 8</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {gamesToday.map((game, index) => (
                                                <td key={index}>
                                                    {game.pattiResult}<br />{game.tripleResult}
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="accordion-item">
                <div className="accordion-title" onClick={() => setShowLast30Days(!showLast30Days)}>
                    <h3>Results for Last 30 Days</h3>
                </div>
                {showLast30Days && (
                    <div className="accordion-content">
                        {loadingLast30Days ? (
                            <div>Loading last 30 days' table...</div>
                        ) : (
                            <div className="table-wrapper">
                                <h3>Showing Results for Last 30 Days</h3>
                                <table className="fl-table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Bazi 1</th>
                                            <th>Bazi 2</th>
                                            <th>Bazi 3</th>
                                            <th>Bazi 4</th>
                                            <th>Bazi 5</th>
                                            <th>Bazi 6</th>
                                            <th>Bazi 7</th>
                                            <th>Bazi 8</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderTableRows(gamesLast30Days)}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="accordion-item">
                <div className="accordion-title" onClick={() => setShowAll(!showAll)}>
                    <h3>All Results</h3>
                </div>
                {showAll && (
                    <div className="accordion-content">
                        {loadingAll ? (
                            <div>Loading all results table...</div>
                        ) : (
                            <div className="table-wrapper">
                                <h3>Showing All Results</h3>
                                <table className="fl-table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Bazi 1</th>
                                            <th>Bazi 2</th>
                                            <th>Bazi 3</th>
                                            <th>Bazi 4</th>
                                            <th>Bazi 5</th>
                                            <th>Bazi 6</th>
                                            <th>Bazi 7</th>
                                            <th>Bazi 8</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderTableRows(gamesAll)}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ResultTable;
