import React, { useState } from 'react';
import illustration from './../assets/6368592.jpg';
import './authentication.css';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth, db } from '../config/firebase'; // Import the database module from your Firebase configuration
import { collection, addDoc } from 'firebase/firestore';

const RegisterPage = () => {
    const navigate = useNavigate();
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Check if passwords match
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            // Convert email to lowercase
            const lowercasedEmail = email.toLowerCase();

            // Create user with email and password
            const userCredential = await createUserWithEmailAndPassword(auth, lowercasedEmail, password);
            const user = userCredential.user;

            // Set display name directly on user object
            await updateProfile(auth.currentUser, {
                displayName: displayName
            });

            // Add document to the "userProfile" collection
            const docRef = await addDoc(collection(db, "userProfile"), {
                uid: user.uid,
                displayName: displayName,
                emailId: lowercasedEmail,
                type: 'User',
                percentage: 0,
                phoneNumber: phoneNumber,
                wallet: 0,
                accessCode: '',
                fundAddHistory: [],
                fundWithdrawHistory: [],
                betLog: [],
                winLog: [],
                masterEmail: '',
                adminEmail: ''
            });

            console.log("Document written with ID: ", docRef.id);

            navigate('/');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className='login-page'>
            <p className='login-header'>Register Your Account</p>
            <img id='login-illustration' src={illustration} alt="" />
            <form className="cred-group" onSubmit={handleSubmit}>
                <label htmlFor="" className="cred-child-label">
                    <p>Display Name : </p>
                    <input type="text" className="cred-child" placeholder="Display Name" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
                </label>
                <label htmlFor="" className="cred-child-label">
                    <p>Email ID : </p>
                    <input type="email" className="cred-child" placeholder="Email ID" value={email} onChange={(e) => setEmail(e.target.value)} />
                </label>
                <label htmlFor="" className="cred-child-label">
                    <p>Phone Number : </p>
                    <input type="tel" name="PhoneNumber" placeholder='Phone Number' id="" className="cred-child" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                </label>
                <label htmlFor="" className="cred-child-label">
                    <p>Password : </p>
                    <input type="password" className="cred-child" placeholder="Password " value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
                <label htmlFor="" className="cred-child-label">
                    <p>Confirm Password : </p>
                    <input type="password" className="cred-child" placeholder=" Confirm Password " value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </label>
                <button id='credButton' type="submit">Register Now</button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
            <Link to='/login' style={{ textDecoration: 'none', color: 'red', marginBottom: '10px' }}><p>Have Account? Login Here</p></Link>
        </div>
    );
}

export default RegisterPage;
